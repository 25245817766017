import type { Profile } from '@tj-dev/shared-core/dist/types';
import { saveArticleView } from '../actions/save-article-view';
import { saveCoursePageView } from '../actions/save-course-page-view';
import { saveLessonView } from '../actions/save-lesson-view';
import { saveProMainPageView } from '../actions/save-pro-main-page-view';
import { sendArticleView } from '../actions/send-article-view';
import { sendCoursePageView } from '../actions/send-course-page-view';
import { sendLessonView } from '../actions/send-lesson-view';
import { sendProMainPageView } from '../actions/send-pro-main-page-view';
import type { UraniaPageType } from '../types';
import { getArticleMeta, getUraniaMeta } from '../util/window';
import { sendProCollectionPageView } from '../actions/send-pro-collection-page-view';

type Helpers = {
  send: (profile?: Profile) => void;
  save: () => void;
};
const URANIA_VIEW_MAP: Record<UraniaPageType, Helpers> = {
  lesson: {
    send: sendLessonView,
    save: saveLessonView,
  },
  quiz: {
    send: sendLessonView,
    save: saveLessonView,
  },
  course: {
    send: sendCoursePageView,
    save: saveCoursePageView,
  },
  main: {
    send: sendProMainPageView,
    save: saveProMainPageView,
  },
  collection: {
    send: sendProCollectionPageView,
    save: () => {
      // Mindbox сам записывает часть анонимных просмотров,
      // поэтому пока договорились не добавлять отдельную логику для них.
    },
  },
};

export function handlePageView(profile?: Profile) {
  const articleMeta = getArticleMeta();
  if (articleMeta) {
    sendArticleView(profile);

    if (!profile) {
      saveArticleView();
    }

    return;
  }

  const uraniaMeta = getUraniaMeta();
  if (uraniaMeta) {
    URANIA_VIEW_MAP[uraniaMeta.pageType]?.send(profile);

    if (!profile) {
      URANIA_VIEW_MAP[uraniaMeta.pageType]?.save();
    }
  }
}
