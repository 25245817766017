import { sendBannerClickedEvent as sendBannerClickedAnalyticsEvent } from '../analytics';
import type { BannerEventParams } from '../types';
import { getProfile } from '../util/shared-core-v3';
import { getArticleMeta } from '../util/window';

export function sendBannerClickedEvent(params: BannerEventParams) {
  if (!params.id || !params.href) {
    return;
  }

  const articleMeta = getArticleMeta();
  const profile = getProfile();

  sendBannerClickedAnalyticsEvent({
    entityType: 'mindbox.popup',
    socialId: String(profile?.id ?? ''),
    ...params,
    ...articleMeta,
  });
}
